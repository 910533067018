import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () =>
      import( './use-cases/home/home.module' )
        .then( ( m ) => m.HomeModule ),
  },
  {
    path: 'audio',
    loadChildren: () =>
      import( './use-cases/audio-classroom/audio-classroom.module' )
        .then( ( m ) => m.AudioClassroomPageModule ),
  },
  {
    path: 'video',
    loadChildren: () =>
      import( './use-cases/video-classroom/video-classroom.module' )
        .then( ( m ) => m.VideoClassroomPageModule ),
  },
  {
    path: 'video-conference',
    loadChildren: () =>
      import( './use-cases/video-conference/video-conference.module' )
        .then( ( m ) => m.VideoConferencePageModule ),
  },
];

@NgModule( {
  imports: [
    RouterModule.forRoot( routes, {
      preloadingStrategy: PreloadAllModules,
    } ),
  ],
  exports: [ RouterModule ],
} )
export class AppRoutingModule { }
